import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";

const Service = (props) => {
  return (
    <>
      {props.img === "left" ? (
        //if image postion left
        <div className="w-[90%] overflow-hidden sm:w-[70%] rounded-xl md:rounded py-[20px] md:py-0 bg-white lg:w-[80%] xl:w-[70%] justify-center items-center flex flex-wrap mx-auto my-10">
          <div className="w-[100%] md:w-[50%] rounded-xl">
            {props.data.img.length > 1 ? (
              <Carousel
                autoPlay={true}
                showThumbs={false}
                infiniteLoop={true}
                showIndicators={false}
                transitionTime={1000}
              >
                {props.data.img.map((item, index) => (
                  <div
                    key={index}
                    className="w-[90%] m-auto block md:w-full rounded-xl"
                  >
                    <img
                      src={item}
                      alt=""
                      className="h-[22rem] w-full rounded-xl"
                    />
                  </div>
                ))}
              </Carousel>
            ) : (
              <img
                className="w-[90%] m-auto block md:w-full h-[22rem] rounded-xl"
                src={props.data.img[0]}
                alt=""
              />
            )}
          </div>
          <div className="flex flex-col w-[90%] mx-auto md:mx-0 md:w-[50%] justify-center items-start gap-6 md:pl-[20px]">
            <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative gap-4">
              <p className="text-[20px] sm:text-[25px] mt-4 md:mt-0 font-semibold text-left text-[#2f2f2f]">
                {props.data.title}
              </p>
              <p className="w-[100%] text-sm lg:text-base xl:text-xl text-left text-[#61748e]">
                {props.data.des}
              </p>
            </div>
            <div className="flex flex-wrap justify-start items-start gap-4">
              {props.data.reviews.map((data, idx) => {
                return (
                  <div key={idx} className="flex">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 top-0.5"
                      preserveAspectRatio="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13.7071 8.70711Z"
                        fill="#8DC73F"
                      ></path>
                    </svg>
                    <p className=" ml-[10px] text-sm font-medium text-left text-[#2f2f2f]">
                      {data}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 gap-2.5 px-6 py-2 rounded-[50px] bg-[#0f74bb]">
              <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-[9px]">
                <p className="flex-grow-0 flex-shrink-0 text-base font-semibold text-center text-white">
                  <Link to="/contact">Get in touch</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        //if image position right
        <div className=" w-[90%] overflow-hidden sm:w-[70%] rounded-xl md:rounded  py-[20px] md:py-0 bg-white lg:w-[80%] xl:w-[70%] justify-center items-center flex flex-wrap mx-auto my-10">
          <div className="w-[100%] md:w-[50%] block md:hidden rounded-xl">
            {props.data.img.length > 1 ? (
              <Carousel
                autoPlay={true}
                showThumbs={false}
                infiniteLoop={true}
                showIndicators={false}
                transitionTime={1000}
              >
                {props.data.img.map((item, index) => (
                  <div
                    key={index}
                    className="w-[90%] m-auto block md:w-full rounded-xl"
                  >
                    <img
                      src={item}
                      alt=""
                      className="h-[22rem] w-full rounded-xl"
                    />
                  </div>
                ))}
              </Carousel>
            ) : (
              <img
                className="w-[90%] m-auto block md:w-full h-[22rem] rounded-xl"
                src={props.data.img[0]}
                alt=""
              />
            )}
          </div>
          <div className="flex flex-col w-[90%] mx-auto md:mx-0 md:w-[50%] justify-center items-start gap-6 md:pr-[20px]">
            <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative gap-4">
              <p className="text-[20px] sm:text-[25px] mt-4 md:mt-0 font-semibold text-left text-[#2f2f2f]">
                {props.data.title}
              </p>
              <p className="w-[100%] text-sm lg:text-base xl:text-xl text-left text-[#61748e]">
                {props.data.des}
              </p>
            </div>
            <div className="flex flex-wrap justify-start items-start gap-4">
              {props.data.reviews.map((data, idx) => {
                return (
                  <div key={idx} className="flex">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 top-0.5"
                      preserveAspectRatio="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13.7071 8.70711Z"
                        fill="#8DC73F"
                      ></path>
                    </svg>
                    <p className=" ml-[10px] text-sm font-medium text-left text-[#2f2f2f]">
                      {data}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 gap-2.5 px-6 py-2 rounded-[50px] bg-[#0f74bb]">
              <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-[9px]">
                <p className="flex-grow-0 flex-shrink-0 text-base font-semibold text-center text-white">
                  <Link to="/contact">Get in touch</Link>
                </p>
              </div>
            </div>
          </div>
          <div className="w-[100%] md:w-[50%] hidden md:block rounded-xl">
            {props.data.img.length > 1 ? (
              <Carousel
                autoPlay={true}
                showThumbs={false}
                infiniteLoop={true}
                showIndicators={false}
                transitionTime={1000}
              >
                {props.data.img.map((item, index) => (
                  <div
                    key={index}
                    className="w-[90%] m-auto block md:w-full rounded-xl"
                  >
                    <img
                      src={item}
                      alt=""
                      className="h-[22rem] w-full rounded-xl"
                    />
                  </div>
                ))}
              </Carousel>
            ) : (
              <img
                className="w-[90%] m-auto block md:w-full h-[22rem] rounded-xl"
                src={props.data.img[0]}
                alt=""
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Service;
