import React from "react";
import { Link } from "react-router-dom";

//import images
import bg from "../../assets/Images/home/Sloarbg.jpg";

//import components
import Navbar from '../../components/Navbar'

const Header = () => {
  return (

    <>
      {/* import navbar */}

      <Navbar />
      <div
        style={{ backgroundImage: `url(${bg})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }}
        className="w-[100%] h-[90vh] overflow-hidden flex flex-col justify-center items-center"
      >

        <section className="overflow-hidden">
          <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
            <div className="max-w-3xl 3xl:max-w-full mx-auto text-center">
              <h1 style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' }} className="text-3xl 3xl:text-[100px] font-semibold tracking-tight text-white sm:text-4xl xl:text-6xl sm:tracking-tight">
              Let’s make an important change towards the future. 
              </h1>
              <p style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' }} className="mt-6 text-base 3xl:text-4xl leading-7 text-white lg:leading-8 lg:text-lg px-10">
              Our innovative solutions make clean energy possible for households, businesses, property owners, and more.

              </p>
              <div className="mt-8">
                <a
                  href="https://drive.google.com/file/d/1CbxYrYWw-MEsr5ivom2wVqTWi7jSr9NY/view"
                  target={'_blank'}
                  className="inline-flex items-center justify-center px-8 py-4 text-base font-medium text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700 mb-2"
                  role="button"
                >
                  Know More
                </a>
              </div>
              <p className="mt-8 text-sm font-normal text-white hidden">Play Video</p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Header;
