import React from "react";
//images
import img1 from "../../assets/Images/logo0.svg";
import img2 from "../../assets/Images/logo1.svg";
import img3 from "../../assets/Images/logo2.svg";

const Comments = () => {
  return (
    <section className="py-12 bg-white sm:py-16 lg:py-20 xl:py-24">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="font-[inter] text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
            Hear what people say about us
          </h2>
          <p className="text-center text-sm text-[#61748E] mt-[15px]">
            We have contributed a lot.
          </p>
        </div>

        <div className="grid max-w-md grid-cols-1 gap-5 mx-auto mt-12 sm:mt-16 lg:grid-cols-3 lg:max-w-none">
          <div className="bg-[#E9F6FF] rounded-2xl">
            <div className="p-8">
              <blockquote>
                <p className="text-xl font-medium leading-9 text-gray-900">
                  "Our solar needs were fulfilled by Aditya Energy. The staff
                  helped with property purchase, site approval, and solar
                  panels. Our project benefited from their switchyard skills. We
                  recommend Aditya Energy for renewable energy."
                </p>
              </blockquote>
              <p className="mt-6 text-base font-semibold text-gray-900">
                Hinduja Renewables
              </p>
              <div className="flex items-center justify-start pt-5 mt-5 border-t border-gray-200">
                <img className="w-auto h-7" src={img1} alt="" />
              </div>
            </div>
          </div>

          <div className="bg-[#E9F6FF] rounded-2xl">
            <div className="p-8">
              <blockquote>
                <p className="text-xl font-medium leading-9 text-gray-900">
                  "We are thrilled to collaborate with Aditya Energy. They
                  assist our small business in locating the greatest
                  Transmission line Suppliers. We hope to continue working with
                  them in the future because we had a wonderful experience."
                </p>
              </blockquote>
              <p className="mt-6 text-base font-semibold text-gray-900">
                Hild Energy
              </p>
              <div className="flex items-center justify-start pt-5 mt-5 border-t border-gray-200">
                <img className="w-auto h-7" src={img2} alt="" />
              </div>
            </div>
          </div>

          <div className="bg-[#E9F6FF] rounded-2xl">
            <div className="p-8">
              <blockquote>
                <p className="text-xl font-medium leading-9 text-gray-900">
                  "Aditya Energy has performed admirably. They are highly
                  competent and required minimal direction from us. I would
                  recommend them to anyone who needs assistance obtaining
                  utility approval for their projects."
                </p>
              </blockquote>
              <p className="mt-6 text-base font-semibold text-gray-900">
                Renew Power
              </p>
              <div className="flex items-center justify-start pt-5 mt-5 border-t border-gray-200">
                <img className="w-auto h-7" src={img3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Comments;
