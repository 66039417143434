import React from "react";
//images
import img1 from "../../assets/Images/logo0.svg";
import img2 from "../../assets/Images/logo1.svg";
import img3 from "../../assets/Images/logo2.svg";
import img4 from "../../assets/Images/logo3.svg";
import img5 from "../../assets/Images/logo4.svg";
import img6 from "../../assets/Images/logo5.svg";
import img7 from "../../assets/Images/logo6.svg";
import img8 from "../../assets/Images/logo7.svg";

const Companies = () => {
  return (
    <section className="py-12 sm:py-16 lg:py-20 xl:py-24">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="px-6 text-center sm:px-0">
          <h2 className="text-2xl font-semibold text-gray-900 sm:text-3xl lg:text-4xl">
            Trusted by companies of all sizes
          </h2>

          <p className="text-center text-sm text-[#61748E] mt-[15px]">
            They trusted us, now it’s your turn!
          </p>
        </div>

        <div className="flex flex-wrap items-center justify-center max-w-5xl gap-8 mx-auto mt-12 sm:mt-16 sm:gap-y-12 md:gap-16">
          <img
            className="object-contain w-32 max-w-full sm:h-8 md:h-10 sm:w-44"
            src={img1}
            alt=""
          />
          <img
            className="object-contain w-32 max-w-full sm:h-8 md:h-10 sm:w-44"
            src={img2}
            alt=""
          />
          <img
            className="object-contain w-32 max-w-full sm:h-8 md:h-10 sm:w-44"
            src={img3}
            alt=""
          />
          <img
            className="object-contain w-32 max-w-full sm:h-8 md:h-10 sm:w-44"
            src={img4}
            alt=""
          />
          <img
            className="object-contain w-32 max-w-full sm:h-8 md:h-10 sm:w-40"
            src={img5}
            alt=""
          />
          <img
            className="object-contain w-32 max-w-full sm:h-8 md:h-9 sm:w-40"
            src={img6}
            alt=""
          />
          <img
            className="object-contain w-32 max-w-full sm:h-8 md:h-10 sm:w-40"
            src={img7}
            alt=""
          />
          <img
            className="object-contain w-32 max-w-full sm:h-8 md:h-10 sm:w-40"
            src={img8}
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default Companies;
