import React, { useEffect } from "react";

//import components
import Achievement from "../../components/Achievements";
import Companies from "../../components/Companies";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import LeaderShipTeam from "../../components/LeaderShipTeam";
import WhyAditya from "../../components/WhyAditya";
import AboutUs from "./AboutUs";
import Brands from "./Brands";
import Comments from "./Comments";
import Projects from "./Projects";
import Services from "./Services";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="">
      {/* import Navbar or achievement */}

      <Header />

      <Achievement />

      {/* Why aditya */}
      <WhyAditya />

      {/* about us */}

      <AboutUs />

      {/* Leader ship */}
      <LeaderShipTeam />

      {/* services */}

      <Services />

      {/* Projects */}

      <Projects />

      {/* comments */}

      <Comments />

      {/* companies */}

      <Companies />

      {/* Brands */}
      <Brands />

      {/* footer */}

      <Footer />
    </div>
  );
};

export default Home;
