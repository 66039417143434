import React from "react";

//import images

import commandImg1 from "../../assets/Images/home/command.svg";
import commandImg2 from "../../assets/Images/home/command1.svg";
import commandImg3 from "../../assets/Images/home/command2.svg";
import commandImg4 from "../../assets/Images/home/command3.svg";

const WhyAditya = () => {
  const adityaDetails = [
    {
      title: "Personalized",
      de: "We don't just sell products; we help you use them. We'll help you maximise your energy use so you may live your best life.",
      img: commandImg1,
    },
    {
      title: "Measured Results",
      de: "We help companies achieve their goals, whether it's improving operations, cutting costs, or boosting profitability.",
      img: commandImg2,
    },
    {
      title: "Top Quality",
      de: "Aditya Energy is a top-quality company. We are committed to providing reliable, affordable energy and we're proud of it!",
      img: commandImg3,
    },
    {
      title: "Experience",
      de: "We're professionals who make sure you have the latest technology and services for your home or business.",
      img: commandImg4,
    },
  ];
  return (
    <div className="max-w-7xl mx-auto">
      <h1 className="font-[inter] text-center text-3xl text-[#2F2F2F] font-bold">
        Why Aditya Energy?
      </h1>
      <p className="font-[inter] text-center text-sm text-[#61748E] mt-[15px]">
        We are at the top of the chart!
      </p>
      <div className="w-[80%] mx-auto sm:w-full m-auto flex mt-[50px] flex-wrap">
        {adityaDetails.map((data, idx) => {
          return (
            <div
              key={idx}
              className="bg-[#E9F6FF] rounded mx-auto my-[20px] pt-[60px] m-[1%] relative sm:w-[45%] md:w-[30%] lg:w-[22%] flex flex-col justify-center items-center p-[20px]"
            >
              <div className="absolute top-[-30px] w-[70px] bg-[#E9F6FF] h-[70px] rounded-[50%] flex justify-center items-center border-8 border-white">
                <img src={data.img} alt="" />
              </div>
              <h3 className="font-[inter] font-[600] text-lg mb-3 text-center">
                {data.title}
              </h3>
              <p className="font-[inter] text-xs xl:text-sm text-center">
                {data.de}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WhyAditya;
