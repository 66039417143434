import React, { useEffect } from "react";

//import components
import Companies from "../../components/Companies";
import Footer from "../../components/Footer";
import FooterContent from "../../components/FooterContent";
import Navbar from "../../components/Navbar";
import Service from "../../components/Services";
import WhyAditya from "../../components/WhyAditya";

//Land Procurement
import Land_Procurement_1 from "../../assets/Images/servicesOrGallery/Land_Procurement/land-1.jpg";
import Land_Procurement_2 from "../../assets/Images/servicesOrGallery/Land_Procurement/land-2.jpg";
import Land_Procurement_3 from "../../assets/Images/servicesOrGallery/Land_Procurement/land-3.jpg";
import Land_Procurement_4 from "../../assets/Images/servicesOrGallery/Land_Procurement/land-4.jpg";

//Construction of Transmission Line
import Construction_of_Transmission_Line_1 from "../../assets/Images/servicesOrGallery/Construction_of_Transmission_Line/constracution-1.jpeg";
import Construction_of_Transmission_Line_2 from "../../assets/Images/servicesOrGallery/Construction_of_Transmission_Line/constracution-2.jpeg";
import Construction_of_Transmission_Line_3 from "../../assets/Images/servicesOrGallery/Construction_of_Transmission_Line/constracution-3.jpeg";
import Construction_of_Transmission_Line_4 from "../../assets/Images/servicesOrGallery/Construction_of_Transmission_Line/constracution-4.jpeg";
import Construction_of_Transmission_Line_5 from "../../assets/Images/servicesOrGallery/Construction_of_Transmission_Line/constracution-5.jpeg";
import Construction_of_Transmission_Line_6 from "../../assets/Images/servicesOrGallery/Construction_of_Transmission_Line/constracution-6.jpeg";
import Construction_of_Transmission_Line_7 from "../../assets/Images/servicesOrGallery/Construction_of_Transmission_Line/constracution-7.jpeg";
import Construction_of_Transmission_Line_8 from "../../assets/Images/servicesOrGallery/Construction_of_Transmission_Line/constracution-8.jpeg";

//Land and Site related approvals
import Land_and_Site_related_1 from "../../assets/Images/servicesOrGallery/Land_and_Site_related/img-1.jpg";

// Construction of Bay (Substation)
import Construction_of_Bay_1 from "../../assets/Images/servicesOrGallery/Construction_of_Bay/img-1.jpg";
import Construction_of_Bay_2 from "../../assets/Images/servicesOrGallery/Construction_of_Bay/img-2.jpg";
import Construction_of_Bay_3 from "../../assets/Images/servicesOrGallery/Construction_of_Bay/img-3.jpg";
import Construction_of_Bay_4 from "../../assets/Images/servicesOrGallery/Construction_of_Bay/img-4.jpg";
import Construction_of_Bay_5 from "../../assets/Images/servicesOrGallery/Construction_of_Bay/img-5.jpg";
import Construction_of_Bay_6 from "../../assets/Images/servicesOrGallery/Construction_of_Bay/img-6.jpg";
import Construction_of_Bay_7 from "../../assets/Images/servicesOrGallery/Construction_of_Bay/img-7.jpg";
import Construction_of_Bay_8 from "../../assets/Images/servicesOrGallery/Construction_of_Bay/img-8.jpg";

//Right of Way
import Right_of_Way_1 from "../../assets/Images/servicesOrGallery/Right_of_Way/img-1.jpg";
import Right_of_Way_2 from "../../assets/Images/servicesOrGallery/Right_of_Way/img-2.jpg";
import Right_of_Way_3 from "../../assets/Images/servicesOrGallery/Right_of_Way/img-3.jpg";

//Complete_Project_Approvals
import Complete_Project_Approvals_1 from "../../assets/Images/servicesOrGallery/Complete_Project_Approvals/img-1.jpg";


//Construction of Switch Yard (Plant Side)
import Construction_of_Switch_Yard_1 from "../../assets/Images/servicesOrGallery/Construction_of_Switch_Yard/img-1.jpeg";
import Construction_of_Switch_Yard_2 from "../../assets/Images/servicesOrGallery/Construction_of_Switch_Yard/img-2.jpeg";
import Construction_of_Switch_Yard_3 from "../../assets/Images/servicesOrGallery/Construction_of_Switch_Yard/img-3.jpeg";
import Construction_of_Switch_Yard_4 from "../../assets/Images/servicesOrGallery/Construction_of_Switch_Yard/img-4.jpeg";
import Construction_of_Switch_Yard_5 from "../../assets/Images/servicesOrGallery/Construction_of_Switch_Yard/img-5.jpeg";
import Construction_of_Switch_Yard_6 from "../../assets/Images/servicesOrGallery/Construction_of_Switch_Yard/img-6.jpeg";
import Construction_of_Switch_Yard_7 from "../../assets/Images/servicesOrGallery/Construction_of_Switch_Yard/img-7.jpeg";
import Construction_of_Switch_Yard_8 from "../../assets/Images/servicesOrGallery/Construction_of_Switch_Yard/img-8.jpeg";

//Post commissioning monthly billing adjustments
import Post_commissioning_monthly_billing_adjustments from "../../assets/Images/servicesOrGallery/Post_commissioning_monthly_billing_adjustments/PCMBA.jpg";

//Roof Top Solar
import Roof_Top_Solar_1 from "../../assets/Images/servicesOrGallery/Roof_Top_Solar/img-1.jpg";
import Roof_Top_Solar_2 from "../../assets/Images/servicesOrGallery/Roof_Top_Solar/img-2.jpg";
import Roof_Top_Solar_3 from "../../assets/Images/servicesOrGallery/Roof_Top_Solar/img-3.jpg";
import Roof_Top_Solar_4 from "../../assets/Images/servicesOrGallery/Roof_Top_Solar/img-4.jpg";
import Roof_Top_Solar_5 from "../../assets/Images/servicesOrGallery/Roof_Top_Solar/img-5.jpg";

//EPC
import EPC_1 from "../../assets/Images/servicesOrGallery/EPC/img-1.jpeg";
import EPC_2 from "../../assets/Images/servicesOrGallery/EPC/img-2.jpeg";
import EPC_3 from "../../assets/Images/servicesOrGallery/EPC/img-3.jpeg";

import headerBG from "../../assets/Images/home/Sloarbg.jpg";


const Services = () => {
  const servicesDetails1 = {
    title: "Land Procurement",
    des: "We offer a wide range of land sites for purchase or lease, with sizes ranging from 5 to 500 acres. We consider various factors like proximity to a substation and evacuation feasibility to select the most suitable site for each project.",
    img: [Land_Procurement_1, Land_Procurement_2, Land_Procurement_3, Land_Procurement_4],
    reviews: ["Top Quality", "Measured Results", "Personalized Work"],
  };

  const servicesDetails2 = {
    title: "Construction of Transmission Line",
    des: "We specialise in constructing and installing power lines for evacuation infrastructure, covering a range of voltage levels from 110/66/33/11 kilovolts. Our team can handle every aspect of the process, from land site to substation.",
    img: [
      Construction_of_Transmission_Line_1,
      Construction_of_Transmission_Line_2,
      Construction_of_Transmission_Line_3,
      Construction_of_Transmission_Line_4,
      Construction_of_Transmission_Line_5,
      Construction_of_Transmission_Line_6,
      Construction_of_Transmission_Line_7,
      Construction_of_Transmission_Line_8
    ],
    reviews: ["Top Quality", "Measured Results", "Personalized Work"],
  };

  const servicesDetails3 = {
    title: "Land and Site related approvals",
    des: "We offer services to help clients navigate the land ownership transfer process for solar projects, handling everything from paperwork to mutation. Our team has the expertise and resources to make the process smooth and stress-free.",
    img: [Land_and_Site_related_1],
    reviews: ["Top Quality", "Measured Results", "Personalized Work"],
  };

  const servicesDetails4 = {
    title: "Construction of Bay (Substation)",
    des: "We construct and install transmission infrastructure for electricity injection into substation grids at various voltage levels. Our team has the expertise and resources to efficiently install power evacuation infrastructure.",
    img: [Construction_of_Bay_1, Construction_of_Bay_2, Construction_of_Bay_3, Construction_of_Bay_4, Construction_of_Bay_5, Construction_of_Bay_6, Construction_of_Bay_7, Construction_of_Bay_8],
    reviews: ["Top Quality", "Measured Results", "Personalized Work"],
  };

  const servicesDetails5 = {
    title: "Right of Way ",
    des: "We assist clients in obtaining approvals for electricity line installation on private or government land. Our team has the expertise and resources to guide clients through the approval process smoothly.",
    img: [Right_of_Way_1, Right_of_Way_2, Right_of_Way_3],
    reviews: ["Top Quality", "Measured Results", "Personalized Work"],
  };

  const servicesDetails6 = {
    title: "Complete Project Approvals",
    des: "When it comes to solar energy projects, our staff is dedicated to acquiring all necessary approvals and meeting regulatory requirements in order to guarantee a simplified and stress-free experience for our customers.",
    img: [Complete_Project_Approvals_1],
    reviews: ["Top Quality", "Measured Results", "Personalized Work"],
  };

  const servicesDetails7 = {
    title: "Construction of Switch Yard (Plant Side)",
    des: "We specialise in constructing and installing evacuation infrastructure at various voltage levels to transmit electricity into power lines. Our team can handle every aspect of the process.",
    img: [
      Construction_of_Switch_Yard_1,
      Construction_of_Switch_Yard_2,
      Construction_of_Switch_Yard_3,
      Construction_of_Switch_Yard_4,
      Construction_of_Switch_Yard_5,
      Construction_of_Switch_Yard_6,
      Construction_of_Switch_Yard_7,
      Construction_of_Switch_Yard_8,
    ],
    reviews: ["Top Quality", "Measured Results", "Personalized Work"],
  };

  const servicesDetails8 = {
    title: "Post commissioning monthly billing adjustments",
    des: "Let us handle the operation and maintenance of your power plant with our post commissioning and billing adjustment services. Our team can manage every aspect of the process, allowing you to focus on other priorities.",
    img: [Post_commissioning_monthly_billing_adjustments],
    reviews: ["Top Quality", "Measured Results", "Personalized Work"],
  };

  const servicesDetails9 = {
    title: "Roof Top Solar",
    des: "We do Roof Top for both residential and commercial clients which include system design, equipment procurement, project management, solar panel installation, and reliable support.",
    img: [
      Roof_Top_Solar_1,
      Roof_Top_Solar_2,
      Roof_Top_Solar_3,
      Roof_Top_Solar_4,
      Roof_Top_Solar_5,
    ],
    reviews: ["Top Quality", "Measured Results", "Personalized Work"],
  };

  const servicesDetails10 = {
    title: "EPC",
    des: "We provide end-to-end services such as consultative sales, system design, equipment procurement, project management, solar panel installation, and reliable support should there be maintenance or warranty claims, if any.",
    img: [
      EPC_1,
      EPC_2,
      EPC_3,
      
    ],
    reviews: ["Top Quality", "Measured Results", "Personalized Work"],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="">
      <Navbar />

      <section
        style={{
          backgroundImage: `url(${headerBG})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
        }}
        className="py-[100px] overflow-hidden"
      >
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="mt-20 text-3xl font-semibold tracking-tight text-white sm:text-4xl xl:text-6xl sm:tracking-tight">
              Our Services are Powerful
            </h1>
            <p className="mt-6 text-sm leading-7 text-white lg:leading-5 lg:text-base lg:px-20">
              Our innovative solutions make clean energy possible for households
              businesses, property owners, and more.
            </p>
          </div>
        </div>
      </section>

      <Service img="left" data={servicesDetails1} />
      <Service img="right" data={servicesDetails2} />
      <Service img="left" data={servicesDetails3} />
      <Service img="right" data={servicesDetails4} />
      <Service img="left" data={servicesDetails5} />
      <Service img="right" data={servicesDetails6} />
      <Service img="left" data={servicesDetails7} />
      <Service img="right" data={servicesDetails8} />
      <Service img="left" data={servicesDetails9} />
      <Service img="right" data={servicesDetails10} />

      {/* Why Aditya */}
      <WhyAditya />
      {/* Companies */}
      <Companies />
      {/* FooterContent */}
      <FooterContent />
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Services;
