import React, { useEffect } from "react";

//import components
import Companies from "../../components/Companies";
import Footer from "../../components/Footer";
import FooterContent from "../../components/FooterContent";
import Navbar from "../../components/Navbar";
import WhyAditya from "../../components/WhyAditya";

//import image
import headerBG from "../../assets/Images/servicesOrGallery/BG.jpg";
import img1 from "../../assets/Images/servicesOrGallery/services1.svg";
import img10 from "../../assets/Images/servicesOrGallery/services10.svg";
import img11 from "../../assets/Images/servicesOrGallery/services11.svg";
import img12 from "../../assets/Images/servicesOrGallery/services12.svg";
import img13 from "../../assets/Images/servicesOrGallery/services13.svg";
import img14 from "../../assets/Images/servicesOrGallery/services14.svg";
import img15 from "../../assets/Images/servicesOrGallery/services15.svg";
import img16 from "../../assets/Images/servicesOrGallery/services16.svg";
import img17 from "../../assets/Images/servicesOrGallery/services17.svg";
import img18 from "../../assets/Images/servicesOrGallery/services18.svg";
import img19 from "../../assets/Images/servicesOrGallery/services19.svg";
import img2 from "../../assets/Images/servicesOrGallery/services2.svg";
import img20 from "../../assets/Images/servicesOrGallery/services20.svg";
import img21 from "../../assets/Images/servicesOrGallery/services21.svg";
import img22 from "../../assets/Images/servicesOrGallery/services22.svg";
import img23 from "../../assets/Images/servicesOrGallery/services23.svg";
import img24 from "../../assets/Images/servicesOrGallery/services24.svg";
import img25 from "../../assets/Images/servicesOrGallery/services25.svg";
import img27 from "../../assets/Images/servicesOrGallery/services27.svg";
import img28 from "../../assets/Images/servicesOrGallery/services28.svg";
import img29 from "../../assets/Images/servicesOrGallery/services29.svg";
import img3 from "../../assets/Images/servicesOrGallery/services3.svg";
import img30 from "../../assets/Images/servicesOrGallery/services30.svg";
import img31 from "../../assets/Images/servicesOrGallery/services31.svg";
import img32 from "../../assets/Images/servicesOrGallery/services32.svg";
import img33 from "../../assets/Images/servicesOrGallery/services33.svg";
import img4 from "../../assets/Images/servicesOrGallery/services4.svg";
import img5 from "../../assets/Images/servicesOrGallery/services5.svg";
import img6 from "../../assets/Images/servicesOrGallery/services6.svg";
import img7 from "../../assets/Images/servicesOrGallery/services7.svg";
import img8 from "../../assets/Images/servicesOrGallery/services8.svg";
import img9 from "../../assets/Images/servicesOrGallery/services9.svg";

//import third party
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Gallery = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      {/* navbar */}
      <Navbar />

      <section
        style={{
          backgroundImage: `url(${headerBG})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
        }}
        className="py-[100px] overflow-hidden pb-[150px]"
      >
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="mt-20 text-3xl font-semibold tracking-tight text-white sm:text-4xl xl:text-6xl sm:tracking-tight">
              Project Gallery
            </h1>
            <p className="mt-6 text-sm leading-7 text-white lg:leading-5 lg:text-base lg:px-20">
              Our innovative solutions make clean energy possible for households
              businesses, property owners, and more.
            </p>
          </div>
        </div>
      </section>

      <div className="max-w-full 2xl:max-w-7xl mx-auto">
        <div>
          <h1 className="text-2xl mt-[50px] mb-[20px] ml-[20px] font-[inter] font-[600]">
            15MW - Arupukottai, Tamilnadu{" "}
            <span className="font-[400] text-[#2F2F2F] text-[18px] ml-3">
              2016
            </span>
          </h1>
          <Carousel responsive={responsive}>
            <div className="m-[2%]">
              <img src={img1} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img2} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img3} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img4} alt="" />
            </div>
          </Carousel>
        </div>

        <div>
          <h1 className="text-2xl mt-[50px] mb-[20px] ml-[20px] font-[inter] font-[600]">
            15MW - Tuticurin, Tamilnadu{" "}
            <span className="font-[400] text-[#2F2F2F] text-[18px] ml-3">
              2016
            </span>
          </h1>
          <Carousel responsive={responsive}>
            <div className="m-[2%]">
              <img src={img5} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img6} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img7} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img8} alt="" />
            </div>
          </Carousel>
        </div>

        <div>
          <h1 className="text-2xl mt-[50px] mb-[20px] ml-[20px] font-[inter] font-[600]">
            30MW - Dindigul, Tamilnadu{" "}
            <span className="font-[400] text-[#2F2F2F] text-[18px] ml-3">
              2016
            </span>
          </h1>
          <Carousel responsive={responsive}>
            <div className="m-[2%]">
              <img src={img9} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img10} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img11} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img12} alt="" />
            </div>
          </Carousel>
        </div>

        <div>
          <h1 className="text-2xl mt-[50px] mb-[20px] ml-[20px] font-[inter] font-[600]">
            100MW - Arupukottai, Tamilnadu{" "}
            <span className="font-[400] text-[#2F2F2F] text-[18px] ml-3">
              2017
            </span>
          </h1>
          <Carousel responsive={responsive}>
            <div className="m-[2%]">
              <img src={img13} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img14} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img15} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img16} alt="" />
            </div>
          </Carousel>
        </div>

        <div>
          <h1 className="text-2xl mt-[50px] mb-[20px] ml-[20px] font-[inter] font-[600]">
            20MW - Holalkere, Karnataka{" "}
            <span className="font-[400] text-[#2F2F2F] text-[18px] ml-3">
              2017
            </span>
          </h1>
          <Carousel responsive={responsive}>
            <div className="m-[2%]">
              <img src={img17} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img18} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img19} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img20} alt="" />
            </div>
          </Carousel>
        </div>

        <div>
          <h1 className="text-2xl mt-[50px] mb-[20px] ml-[20px] font-[inter] font-[600]">
            100MW - Arupukottai, Tamilnadu{" "}
            <span className="font-[400] text-[#2F2F2F] text-[18px] ml-3">
              2019
            </span>
          </h1>
          <Carousel responsive={responsive}>
            <div className="m-[2%]">
              <img src={img21} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img22} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img23} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img24} alt="" />
            </div>
          </Carousel>
        </div>

        <div>
          <h1 className="text-2xl mt-[50px] mb-[20px] ml-[20px] font-[inter] font-[600]">
            100MW - Ramanathapuram, Tamilnadu{" "}
            <span className="font-[400] text-[#2F2F2F] text-[18px] ml-3">
              2019
            </span>
          </h1>
          <Carousel responsive={responsive}>
            <div className="m-[2%]">
              <img src={img25} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img27} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img28} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img29} alt="" />
            </div>
          </Carousel>
        </div>

        <div className="mb-[20px]">
          <h1 className="text-2xl mt-[50px] mb-[20px] ml-[20px] font-[inter] font-[600]">
            100MW - Thenampatti, Tamilnadu{" "}
            <span className="font-[400] text-[#2F2F2F] text-[18px] ml-3">
              2019
            </span>
          </h1>
          <Carousel responsive={responsive}>
            <div className="m-[2%]">
              <img src={img30} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img31} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img32} alt="" />
            </div>
            <div className="m-[2%]">
              <img src={img33} alt="" />
            </div>
          </Carousel>
        </div>
      </div>

      {/* Why Aditya */}
      <WhyAditya />
      {/* Companies */}
      <Companies />
      {/* FooterContent */}
      <FooterContent />
      {/* Footer */}
      <Footer />
    </>
  );
};

export default Gallery;
