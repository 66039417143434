import React from "react";

const Achievement = () => {
  return (
    <section className="py-20 bg-white">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-xl mx-auto text-center">
          <h2 className="text-3xlfont-[inter]  font-semibold tracking-tight text-gray-900 sm:text-3xl lg:text-5xl">
            Our achievements
          </h2>
          <p className="mb-10font-[inter]  mt-4 text-base font-normal leading-7 text-[#61748E] lg:text-lg lg:mt-6 lg:leading-8">
            Our hard work proves it all!
          </p>
        </div>

        <div className="relative max-w-2xl mx-auto mt-3 overflow-hidden lg:max-w-3xl xl:max-w-none rounded-2xl">
          <div className="relative px-8 py-5 lg:px-12" >
            <div className="grid grid-cols-1 gap-8 mdfirst-letter:gap-12 xl:gap-8 sm:grid-cols-2 xl:grid-cols-4">
              <div className="flex items-center">
                <p className="w-24font-[inter]  text-5xl font-semibold tracking-tight text-[#0F74BB] xl:w-auto shrink-0">
                  45+
                </p>
                <h3 className="ml-5 font-[inter]  text-base font-normal leading-tight text-[#61748E]">
                  Projects
                </h3>
              </div>

              <div className="flex items-center">
                <p className="w-24 font-[inter] text-5xl font-semibold tracking-tight  text-[#0F74BB]  xl:w-auto shrink-0">
                  60+
                </p>
                <h3 className="ml-5 font-[inter]  text-base font-normal leading-tight text-[#61748E]">
                  Corporate Clients
                </h3>
              </div>

              <div className="flex items-center">
                <p className="w-24 text-5xl font-semibold tracking-tight  text-[#0F74BB]  xl:w-auto shrink-0">
                  1.5
                </p>
                <h3 className="ml-5 text-base font-normal leading-tight text-[#61748E]">
                  GW+ Operating Projects
                </h3>
              </div>

              <div className="flex items-center">
                <p className="w-24 text-5xl font-semibold tracking-tight  text-[#0F74BB] xl:w-auto shrink-0">
                  4+
                </p>
                <h3 className="ml-5 text-base font-normal leading-tight text-[#61748E]">
                  Awards Received
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Achievement;
