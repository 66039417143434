import React, { useEffect } from "react";

//import images
import headerBG from "../../assets/Images/servicesOrGallery/BG.jpg";

//import components
import Footer from "../../components/Footer";
import FooterContent from "../../components/FooterContent";
import Navbar from "../../components/Navbar";

const SolarPark = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* navbar */}
      <Navbar />

      <section
        style={{
          backgroundImage: `url(${headerBG})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
        }}
        className="py-[100px] overflow-hidden"
      >
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="mt-20 text-3xl font-semibold tracking-tight text-white sm:text-4xl xl:text-6xl sm:tracking-tight">
              Solar Park
            </h1>
            <p className="mt-6 text-sm leading-7 text-white lg:leading-5 lg:text-base lg:px-20">
              Our innovative solutions make clean energy possible for households
              businesses, property owners, and more.
            </p>
          </div>
        </div>
      </section>

      {/* connect */}
      <section className="py-12 bg-gray-50 sm:py-16 lg:py-20 xl:py-24">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <iframe
            className="min-h-[100vh] md:w-[90%] w-full mx-auto"
            title="solarpark_pdf"
            src="https://drive.google.com/file/d/1A2iSuBm9hFi_NXigoWW_NJNbyxvVsA-v/preview"
            allow="autoplay"
          ></iframe>
        </div>
      </section>

      {/* FooterContent */}
      <FooterContent />
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default SolarPark;
