import React from "react";

//import images
import serviceBG from "../../assets/Images/home/servicesBg.jpg";
import servicesImg1 from "../../assets/Images/home/servicessImg1.svg";
import servicesImg2 from "../../assets/Images/home/servicessImg2.svg";
import servicesImg3 from "../../assets/Images/home/servicessImg3.svg";
import servicesImg4 from "../../assets/Images/home/servicessImg4.svg";

//import third pirty
import { Link } from "react-router-dom";

const Services = () => {
  const servicesDetails = [
    {
      title: "Distributed Solar",
      des: "Systems can provide power during extreme weather or other emergencies.",
      img: servicesImg1,
    },
    {
      title: "Solar Farm",
      des: "Solar systems are utilised in farms to create and store electricity in batteries.",
      img: servicesImg2,
    },
    {
      title: "Wind Farm",
      des: "Wind power stations are groups of wind turbines used to generate electricity.",
      img: servicesImg3,
    },
    {
      title: "Energy Storage",
      des: "Storage options include batteries, thermal, or mechanical systems.",
      img: servicesImg4,
    },
  ];
  return (
    <div
      style={{
        backgroundImage: `url(${serviceBG})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="py-[50px] pt-12"
    >
      <h1 className="font-[inter] text-center text-3xl text-white font-bold">
        What we do
      </h1>
      <p className="font-[inter] text-center text-sm text-white mt-[15px]">
        We offer various energy solutions
      </p>
      <div className="max-w-7xl sm:w-full w-[80%] m-auto flex mt-[50px] flex-wrap">
        {servicesDetails.map((data, idx) => {
          return (
            <div
              key={idx}
              className="bg-white mx-auto my-[20px] rounded-xl m-[1%] sm:w-[45%] md:w-[30%] lg:w-[22%] flex flex-col p-[20px]"
            >
              <img className="w-[50px] h-[50px]" src={data.img} alt="" />
              <div className=" flex flex-col">
                <h3 className="font-[inter] font-[600] text-base mb-3 text-[#2F2F2F] mt-2">
                  {data.title}
                </h3>
                <p className="font-[inter] text-xs xl:text-sm text-[#61748E]">
                  {data.des}
                </p>
                <div className="mt-6">
                  <Link
                    to="/services"
                    title=""
                    className="flex items-center text-sm font-semibold text-blue-600 transition-all duration-200 hover:text-blue-800 hover:underline"
                  >
                    Read More{"/services"}
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Link
        to="/services"
        className="font-[inter] text-[#0F74BB] text-sm mt-5 duration-500 hover:bg-[#0F74BB] hover:text-white bg-white table m-auto px-[30px] py-[10px] rounded-3xl"
      >
        View All Services
      </Link>
    </div>
  );
};

export default Services;
