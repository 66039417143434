import React from "react";

//import images
import manImage3 from "../../assets/Images/home/Advisors1.png";
import manImage4 from "../../assets/Images/home/Advisors2.png";
import manImage1 from "../../assets/Images/home/LeaderShipImg1.svg";
import manImage2 from "../../assets/Images/home/LeaderShipImg3.svg";

const LeaderShipTeam = () => {
  return (
    <section className="py-12 bg-white sm:py-16 lg:py-20 xl:py-24">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="font-[inter] text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
            Our Leadership Team
          </h2>
          <p className="font-[inter] mt-4 text-base font-normal leading-7 text-gray-600 lg:text-lg lg:mt-6 lg:leading-8">
            They guided Aditya Energy!
          </p>
        </div>

        <div className="flex max-w-md justify-center flex-wrap gap-5 mx-auto mt-12 sm:mt-16 lg:mt-20 lg:max-w-5xl lg:grid-cols-3 sm:gap-6 lg:gap-8 xl:gap-10">
          <div className="relative h-[500px] overflow-hidden group rounded-2xl">
            <img
              className="object-cover w-full transition-all duration-200 group-hover:scale-110 group-hover:-rotate-2"
              src={manImage1}
              alt=""
            />
            <div className="absolute inset-0 pointer-events-none bg-gradient-to-t from-gray-900 via-transparent to-transparent opacity-80"></div>
            <div className="absolute bottom-0 left-0 w-full px-6 py-5">
              <div className="flex items-center justify-between">
                <div>
                  <p className="font-[inter] text-base font-semibold text-white">
                    L Uday Metha
                  </p>
                  <p className="font-[inter] mt-1 text-sm font-normal text-gray-300">
                    Managing Partner
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="relative h-[500px] overflow-hidden group rounded-2xl">
            <img
              className="object-cover w-full transition-all duration-200 group-hover:scale-110 group-hover:-rotate-2"
              src={manImage2}
              alt=""
            />
            <div className="absolute inset-0 pointer-events-none bg-gradient-to-t from-gray-900 via-transparent to-transparent opacity-80"></div>
            <div className="absolute bottom-0 left-0 w-full px-6 py-5">
              <div className="flex items-center justify-between">
                <div>
                  <p className="font-[inter] text-base font-semibold text-white">
                    Uday Vinay Metha
                  </p>
                  <p className="font-[inter] mt-1 text-sm font-normal text-gray-300">
                    Managing Partner
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="mt-16">
            <h1 className="text-2xl font-bold text-gray-800 text-center">
              Advisors
            </h1>
            <div className="mt-10 flex justify-center flex-wrap gap-10">
              <img className="max-w-sm rounded-full" src={manImage3} alt="" />
              <img className="max-w-sm rounded-full" src={manImage4} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeaderShipTeam;
