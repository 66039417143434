import React, { useEffect } from "react";

//import components
import Companies from "../../components/Companies";
import Footer from "../../components/Footer";
import FooterContent from "../../components/FooterContent";
import Navbar from "../../components/Navbar";
import WhyAditya from "../../components/WhyAditya";

//import images
import headerBG from "../../assets/Images/servicesOrGallery/BG.jpg";
import img1 from "../../assets/Images/servicesOrGallery/Project/Project-1.svg";
import img2 from "../../assets/Images/servicesOrGallery/Project/Project-2.svg";
import img3 from "../../assets/Images/servicesOrGallery/Project/Project-3.svg";
import img4 from "../../assets/Images/servicesOrGallery/Project/Project-4.svg";
import img5 from "../../assets/Images/servicesOrGallery/Project/Project-5.svg";
import img6 from "../../assets/Images/servicesOrGallery/Project/Project-6.svg";
import img7 from "../../assets/Images/servicesOrGallery/Project/Project-7.svg";
// import img8 from "../../assets/Images/servicesOrGallery/services8.svg";
import img8 from "../../assets/Images/servicesOrGallery/Project/Project-8.svg";
import img9 from "../../assets/Images/servicesOrGallery/Project/Project-9.svg";
import img10 from "../../assets/Images/servicesOrGallery/Project/Project-10.svg";
import img11 from "../../assets/Images/servicesOrGallery/Project/Project-11.svg";
// import img13 from "../../assets/Images/servicesOrGallery/services13.svg";
import img12 from "../../assets/Images/servicesOrGallery/Project/Project-12.svg";
import img13 from "../../assets/Images/servicesOrGallery/Project/Project-13.svg";
import img14 from "../../assets/Images/servicesOrGallery/Project/Project-14.svg";
// import img17 from "../../assets/Images/servicesOrGallery/services17.svg";
import img15 from "../../assets/Images/servicesOrGallery/Project/Project-15.svg";
import img16 from "../../assets/Images/servicesOrGallery/Project/Project-16.svg";
import img17 from "../../assets/Images/servicesOrGallery/Project/Project-17.svg";
// import img21 from "../../assets/Images/servicesOrGallery/services21.svg";
import img18 from "../../assets/Images/servicesOrGallery/Project/Project-18.svg";
// import img23 from "../../assets/Images/servicesOrGallery/services23.svg";
// import img24 from "../../assets/Images/servicesOrGallery/services24.svg";
// import img25 from "../../assets/Images/servicesOrGallery/services25.svg";
import img19 from "../../assets/Images/servicesOrGallery/Project/Project-19.svg";
import img20 from "../../assets/Images/servicesOrGallery/Project/Project-20.svg";
import img21 from "../../assets/Images/servicesOrGallery/Project/Project-2.svg";

const Projects = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const projectDetails = [
    {
      img: img1,
      title: "Pullaniackenpatti	Village, Arupukottai,	Tamil	Nadu",
      des: "Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. piscing pulvinar placerat iodo sem a interdum.",
      infor: "15MW-March 2016",
    },
    {
      img: img2,
      title: "Ottanatham	Village,	Tuticurin, Tamil	Nadu",
      des: "Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. piscing pulvinar placerat iodo sem a interdum.",
      infor: "15MW-March 2016",
    },
    {
      img: img3,
      title: "Viralipatti	Village,	Dindigul,	Tamil Nadu",
      des: "Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. piscing pulvinar placerat iodo sem a interdum.",
      infor: "30MW-September 2016",
    },
    {
      img: img4,
      title: "Thoppalakarai	Village, Arupukottai,	Tamil	Nadu",
      des: "Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. piscing pulvinar placerat iodo sem a interdum.",
      infor: "100MW-March	2017",
    },
    {
      img: img5,
      title: "Holalkere, Karnataka",
      des: "Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. piscing pulvinar placerat iodo sem a interdum.",
      infor: "20MW-October	2017",
    },
    {
      img: img6,
      title: "Pulangal	Village,	Arupukottai, Tamil	Nadu",
      des: "Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. piscing pulvinar placerat iodo sem a interdum.",
      infor: "100MW-March	2019",
    },
    {
      img: img7,
      title: "Kamuthi	Village, Ramanathapuram,	Tamil	Nadu",
      des: "Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. piscing pulvinar placerat iodo sem a interdum.",
      infor: "100MW-March	2019",
    },

    {
      img: img8,
      title: "Thenampatti,	Tamil	Nadu",
      des: "Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. piscing pulvinar placerat iodo sem a interdum.",
      infor: "100MW-September	2019",
    },
    {
      img: img9,
      title: "Thenampatti,	Tamil	Nadu",
      des: "Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. piscing pulvinar placerat iodo sem a interdum.",
      infor: "100MW-September	2019",
    },
    {
      img: img10,
      title: "Thenampatti,	Tamil	Nadu",
      des: "Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. piscing pulvinar placerat iodo sem a interdum.",
      infor: "100MW-September	2019",
    },
    {
      img: img11,
      title: "Hosur, Tamil Nadu",
      des: "Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. piscing pulvinar placerat iodo sem a interdum.",
      infor: "744KW-August 2019",
    },
    {
      img: img12,
      title: "Gummidipudi, Chennai",
      des: "Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. piscing pulvinar placerat iodo sem a interdum.",
      infor: "3.3MW-March 2020",
    },
    {
      img: img13,
      title: "Madurai, Tamil Nadu",
      des: "Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. piscing pulvinar placerat iodo sem a interdum.",
      infor: "296KW-August 2019",
    },
    {
      img: img14,
      title: "Hosur, Tamil Nadu",
      des: "Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. piscing pulvinar placerat iodo sem a interdum.",
      infor: "852KW-January 2020",
    },
    {
      img: img15,
      title: "Coimbatore, Tamil Nadu",
      des: "Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. piscing pulvinar placerat iodo sem a interdum.",
      infor: "1.4MW-March 2020",
    },
    {
      img: img17,
      title: "Viralipatti Village, Dindigul, Tamil Nadu",
      des: "Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. piscing pulvinar placerat iodo sem a interdum.",
      infor: "12 MW-2021",
    },
    {
      img: img18,
      title: "Viralipatti Village, Dindigul, Tamil Nadu",
      des: "Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. piscing pulvinar placerat iodo sem a interdum.",
      infor: "12MW-2021",
    },
    {
      img: img19,
      title: "Viralipatti Village, Dindigul, Tamil Nadu",
      des: "Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. piscing pulvinar placerat iodo sem a interdum.",
      infor: "5.2 MW-2021",
    },
    {
      img: img20,
      title: "Karaikudi, Tamil Nadu",
      des: "Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. piscing pulvinar placerat iodo sem a interdum.",
      infor: "55 MW-2021",
    },
    {
      img: img21,
      title: "Harayana",
      des: "Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. Sollicitudin adipiscing pulvinar placerat id neque. At ut at non massa, viverra cursus. Volutpat cras commodo sem at fringilla bibendum dignissim interdum. Neque, mollis augue in congue. Scelerisque fermentum quis placerat in.Tincidunt lacus, sed eu, nibh nullam nisi, quis velit. piscing pulvinar placerat iodo sem a interdum.",
      infor: "40 MW-2021",
    },
  ];

  return (
    <div>
      {/* Navbar */}
      <Navbar />

      <section
        style={{
          backgroundImage: `url(${headerBG})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
        }}
        className="py-[100px] overflow-hidden"
      >
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="mt-20 text-3xl font-semibold tracking-tight text-white sm:text-4xl xl:text-6xl sm:tracking-tight">
              Few notable projects out of several others undertaken
            </h1>
            <p className="mt-6 text-sm leading-7 text-white lg:leading-5 lg:text-base lg:px-20">
              Our innovative solutions make clean energy possible for households
              businesses, property owners, and more.
            </p>
          </div>
        </div>
      </section>

      {/* Projects */}

      <div className="max-w-7xl m-auto flex mt-[50px] flex-wrap mb-20">
        {projectDetails.map((data, idx) => {
          return (
            <div
              key={idx}
              className="flex my-[2%] sm:m-[1%] w-[90%] sm:w-[80%] overflow-hidden mx-auto md:w-[48%] flex-col justify-start items-start relative gap-6 p-8 rounded-[20px] bg-[#e9f6ff]"
            >
              <div className="flex justify-between w-[95%] sm:w-[100%] items-start flex-grow-0 flex-shrink-0 relative">
                <div className="flex flex-col justify-start items-start relative gap-[5px]">
                  <p className="font-[inter] text-xl font-semibold text-left text-[#2f2f2f]">
                    {data.title}
                  </p>
                  <p className="font-[inter] text-base text-left text-[#61748e]">
                    {data.infor}
                  </p>
                </div>
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="hidden flex-grow-0 flex-shrink-0 w-12 h-12 relative sm:flex"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <rect
                    className="hidden"
                    width="48"
                    height="48"
                    rx="24"
                    fill="#0F74BB"
                  ></rect>
                  <path
                    className="hidden"
                    d="M21 30L27 24L21 18"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </div>
              <img
                src={data.img}
                alt=""
                className="flex-grow-0 flex-shrink-0 w-[556px] h-[350px] rounded-[10px] object-cover"
              />
            </div>
          );
        })}
      </div>
      {/* Why Aditya */}
      <WhyAditya />
      {/* Companies */}
      <Companies />
      {/* FooterContent */}
      <FooterContent />
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Projects;
