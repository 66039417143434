import React from "react";

//imoprt images
import aboutUsBg from "../../assets/Images/home/aboutUsBg.svg";
//import logo
import logo from "../../assets/icon/logo.svg";

//import third prity
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <section className="py-12 bg-white sm:py-16 lg:py-20 xl:py-24">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <h1 className="font-[inter] text-center text-3xl text-[#2F2F2F] font-bold">
          About Us
        </h1>
        <p className="font-[inter] text-center text-sm text-[#61748E] mt-[15px] mb-[50px]">
          We just don’t give up
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-7 gap-x-20 gap-y-12">
          <div className="lg:order-2 lg:col-span-4">
            <div className="max-w-lg lg:max-w-none">
              <h2 className="text-3xl font-[inter] font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-[31px] lg:pr-8">
                We are on a mission to provide energy to every corner of India.
              </h2>
              <p className="mt-4 text-base font-[inter] font-normal text-gray-600 lg:mt-2">
                Over the past decade, the cost of solar technology has fallen
                dramatically. New technologies promise to increase efficiency
                and lower costs further. Solar energy will soon be unbeatable
                compared to fossil fuels. Solar energy has come a long way in a
                decade.
                <span className="block my-3"></span>
                Back in 2010, the global market was small and highly dependent
                on subsidy regimes in countries such as Germany and Italy. This
                year there will be more than 115 gigawatts (GW) of solar
                installed across the world, which is more than all other
                generation technologies put together. It is also increasingly
                low cost, especially in sunnier regions where it has already
                become the lowest-cost form of new electricity generation.
              </p>
              <div className="mt-8">
                <Link
                  to="/aboutUs"
                  title=""
                  className="inline-flex items-center justify-center px-6 py-3 text-base font-medium text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
                  role="button"
                >
                  About us
                </Link>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-8 pt-8 mt-0 border-t border-gray-200 sm:grid-cols-2 xl:mt-10">
              <div>
                <h3 className=" font-[inter] text-lg font-medium text-gray-900">
                  Our History & Vision
                </h3>
                <p className="mt-4 text-sm font-normal text-gray-600">
                  Our vision is to provide clean, affordable energy to everyone.
                </p>
                <div className="mt-6">
                  <Link
                    to="/aboutUs"
                    title=""
                    className="flex items-center text-sm font-semibold text-blue-600 transition-all duration-200 hover:text-blue-800 hover:underline"
                  >
                    {" "}
                    Read More{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-3 h-3 ml-[10px]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                      />
                    </svg>
                  </Link>
                </div>
              </div>

              <div>
                <h3 className="text-lg font-medium text-gray-900">
                  Our Leadership Team
                </h3>
                <p className="mt-4 text-sm font-normal text-gray-600">
                  Our Leadership Team is the backbone of our company.
                </p>
                <div className="mt-6">
                  <Link
                    to="/aboutUs"
                    title=""
                    className="flex items-center text-sm font-semibold text-blue-600 transition-all duration-200 hover:text-blue-800 hover:underline"
                  >
                    {" "}
                    Read More{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-3 h-3 ml-[10px]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundImage: `url(${aboutUsBg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
            className="grid p-12 lg:order-1 lg:col-span-3 rounded-3xl place-items-center"
          >
            <img className="w-full sm:max-w-xs" src={logo} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
