import React from "react";
import { Link } from "react-router-dom";

const Brands = () => {
  return (
    <div className="relative mx-auto overflow-hidden bg-[#3374BB] max-w-7xl rounded-3xl">
      <div className="absolute top-0 left-0">
        <img
          className="w-16 md:w-24 lg:w-32 xl:w-full"
          src="https://landingfoliocom.imgix.net/store/collection/saasui/images/newsletter/3/ring-pattern.svg"
          alt=""
        />
      </div>

      <div className="relative px-8 py-12 md:p-16 xl:p-24">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-3xl font-semibold tracking-tight text-white sm:text-4xl lg:text-5xl">
            Let’s save the planet together!
          </h2>
          <p className="text-center text-sm text-white mt-[15px]">
            Let us help you make an important change towards the future.
          </p>
        </div>
        <Link
          to="/contact"
          className="table items-center justify-center px-10 py-3 mt-5 text-base font-medium text-[#0F74BB] transition-all m-auto duration-200 bg-white border border-transparent rounded-3xl hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 focus:ring-offset-blue-600"
        >
          Get in touch
        </Link>
      </div>
    </div>
  );
};

export default Brands;
