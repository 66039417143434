// ** Router imports
import { useRoutes } from "react-router-dom";

//import pages
import AboutUs from "../pages/AboutUs";
import ContactUs from "../pages/ContactUs";
import Gallery from "../pages/Gallery";
import Home from "../pages/Home";
import Projects from "../pages/Projects";
import Services from "../pages/Services";
import SolarPark from "../pages/SolarPark";

const Router = () => {
  const routes = useRoutes([
    {
      children: [{ path: "/", element: <Home /> }],
    },
    {
      children: [{ path: "/aboutUs", element: <AboutUs /> }],
    },
    {
      children: [{ path: "/services", element: <Services /> }],
    },
    {
      children: [{ path: "/gallery", element: <Gallery /> }],
    },
    {
      children: [{ path: "/projects", element: <Projects /> }],
    },
    {
      children: [{ path: "/contact", element: <ContactUs /> }],
    },
    {
      children: [{ path: "/solar-park", element: <SolarPark /> }],
    },
  ]);

  return routes;
};

export default Router;
