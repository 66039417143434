import React, { useEffect } from "react";

//import components
import Achievement from "../../components/Achievements";
import Companies from "../../components/Companies";
import Footer from "../../components/Footer";
import FooterContent from "../../components/FooterContent";
import LeaderShipTeam from "../../components/LeaderShipTeam";
import Navbar from "../../components/Navbar";
import WhyAditya from "../../components/WhyAditya";

//import images
import logo from "../../assets/icon/logo.svg";
import aboutBg from "../../assets/Images/aboutUs/BG.jpg";
import aboutUsBg from "../../assets/Images/home/aboutUsBg.svg";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* navbar */}
      <Navbar />

      <section
        style={{
          backgroundImage: `url(${aboutBg})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
        }}
        className="py-[150px] overflow-hidden md:pb-[200px]"
      >
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-3xl font-semibold tracking-tight text-white sm:text-4xl xl:text-6xl sm:tracking-tight">
              We do things differently
            </h1>
            <p className="mt-6 text-sm leading-7 text-white lg:leading-5 lg:text-base lg:px-20">
              Our innovative solutions make clean energy possible for households
              businesses, property owners, and more.
            </p>
          </div>
        </div>
      </section>

      {/* Achievement */}

      <div className="max-w-full md:max-w-[80%] 2xl:max-w-7xl md:mt-[-120px] md:rounded-xl overflow-hidden md:shadow-2xl m-auto mb-20">
        <Achievement />
      </div>

      <div className="max-w-7xl mx-auto">
        <h2 className="font-[inter] text-3xl font-semibold tracking-tight text-center text-gray-900 sm:text-4xl lg:text-5xl">
          About Us
        </h2>
        <p className="text-center text-sm text-[#2F2F2F] mt-[15px]">
          We just don’t give up
        </p>
        <div className="flex w-[90%] sm:w-[80%] m-auto mt-10 flex-wrap">
          <div className="relative w-[80%] sm:w-[50%] mx-auto md:mx-0 md:w-[35%] overflow-hidden group rounded-2xl">
            <div
              style={{
                backgroundImage: `url(${aboutUsBg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
              className="h-full w-full grid p-12 lg:order-1 lg:col-span-3 rounded-3xl place-items-center"
            >
              <img className="w-full sm:max-w-xs" src={logo} alt="" />
            </div>
          </div>
          <div className="flex flex-col justify-center items-start mx-auto w-[100%] sm:w-[80%] md:mx-01 md:w-[65%] mt-10 md:mt-0 lg:w-[60%] lg:ml-[5%] pl-[20px] lg:pl-[50px]">
            <div className="flex justify-start items-center">
              <h2 className="text-3xl font-[inter] font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-[31px] lg:pr-8">
                We are on a mission to provide energy to every corner of India.
              </h2>
            </div>
            <p className="mt-5 w-[100%] font-[inter] text-justify sm:text-start lg:w-[80%] text-[14px] lg:text-[18px] text-[#61748e]">
              Over the past decade, the cost of solar technology has fallen
              dramatically. New technologies promise to increase efficiency and
              lower costs further. Solar energy will soon be unbeatable compared
              to fossil fuels. Solar energy has come a long way in a decade.
              <span className="my-3 block"></span>
              Back in 2010, the global market was small and highly dependent on
              subsidy regimes in countries such as Germany and Italy. This year
              there will be more than 115 gigawatts (GW) of solar installed
              across the world, which is more than all other generation
              technologies put together. It is also increasingly low cost,
              especially in sunnier regions where it has already become the
              lowest-cost form of new electricity generation.
            </p>
          </div>
        </div>
        {/* <div className="flex w-[80%] overflow-hidden flex-wrap mx-auto mt-5">
          <div className="w-[100%] sm:w-[70%] mx-auto md:mx-0 md:w-[45%] m-[1%] bg-[#F9F9F9] p-[25px] rounded-xl">
            <h4 className="text-lg font-bold">Our Mission</h4>
            <p>In the coming years,improvements of  technology will ensure that solar becomes even cheaper. It could well be that by 2030, solar will have become the most important source of energy in India</p>
          </div>
          <div className="w-[100%] sm:w-[70%] mx-auto md:mx-0 md:w-[45%] m-[1%] bg-[#F9F9F9] p-[25px] rounded-xl">
            <h4 className="text-lg font-bold">Our Mission</h4>
            <p>In the coming years,improvements of  technology will ensure that solar becomes even cheaper. It could well be that by 2030, solar will have become the most important source of energy in India</p>
          </div>
        </div> */}
      </div>

      {/* LeaderShipTeam */}

      <LeaderShipTeam />

      {/* Why Aditiya */}
      <WhyAditya />

      {/* companies */}
      <Companies />

      {/* FooterContent */}
      <FooterContent />

      {/*footer*/}
      <Footer />
    </div>
  );
};

export default AboutUs;
