import React from "react";
import { Link } from "react-router-dom";

//import images
// import projectImg1 from "../../assets/Images/home/projectImg1.svg";
// import projectImg2 from "../../assets/Images/home/projectImg2.svg";
// import projectImg3 from "../../assets/Images/home/projectImg3.svg";
// import projectImg4 from "../../assets/Images/home/projectImg4.svg";

import img1 from "../../assets/Images/servicesOrGallery/Project/Project-1.svg";
import img2 from "../../assets/Images/servicesOrGallery/Project/Project-2.svg";
import img3 from "../../assets/Images/servicesOrGallery/Project/Project-3.svg";
import img4 from "../../assets/Images/servicesOrGallery/Project/Project-4.svg";

const Projects = () => {
  const projectDetails = [
    {
      title: "Pullaniackenpatti Village, Arupukottai, Tamil Nadu",
      country: "15MW-March 2016",
      img: img1,
    },
    {
      title: "Ottanatham Village, Tuticurin, Tamil Nadu",
      country: "15MW-March 2016",
      img: img2,
    },
    {
      title: "Viralipatti Village, Dindigul, Tamil Nadu",
      country: "30MW-September 2016",
      img: img3,
    },
    {
      title: "Thoppalakarai Village, Arupukottai, Tamil Nadu",
      country: "100MW-March 2017",
      img: img4,
    },
  ];
  return (
    <div className="py-[50px]">
      <h1 className="font-[inter] text-center text-3xl text-[#2F2F2F] font-bold">
        Projects
      </h1>
      <p className="font-[inter] text-center text-sm text-[#61748E] mt-[15px]">
        We have contributed a lot.
      </p>
      <div className="max-w-7xl m-auto flex mt-[50px] flex-wrap">
        {projectDetails.map((data, idx) => {
          return (
            <div
              key={idx}
              className="flex my-[2%] sm:m-[1%] w-[90%] sm:w-[80%] overflow-hidden mx-auto md:w-[48%] flex-col justify-start items-start relative gap-6 p-8 rounded-[20px] bg-[#e9f6ff]"
            >
              <div className="flex justify-between w-[95%] sm:w-[100%] items-start flex-grow-0 flex-shrink-0 relative">
                <div className="flex flex-col justify-start items-start relative gap-[5px]">
                  <p className="font-[inter] text-xl font-semibold text-left text-[#2f2f2f]">
                    {data.title}
                  </p>
                  <p className="font-[inter] text-base text-left text-[#61748e]">
                    {data.country}
                  </p>
                </div>
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="flex-grow-0 flex-shrink-0 w-12 h-12 relative hidden sm:flex"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <rect
                    className="hidden"
                    width="48"
                    height="48"
                    rx="24"
                    fill="#0F74BB"
                  ></rect>
                  <path
                    className="hidden"
                    d="M21 30L27 24L21 18"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </div>
              <img
                src={data.img}
                alt=""
                className="flex-grow-0 flex-shrink-0 w-[556px] h-[350px] rounded-[10px] object-cover"
              />
            </div>
          );
        })}
      </div>
      <Link
        to="/projects"
        className="font-[inter] hover:text-[#0F74BB] text-sm mt-5 duration-500 bg-[#0F74BB] text-white hover:bg-white table m-auto px-[30px] py-[10px] rounded-3xl"
      >
        View All Projects
      </Link>
    </div>
  );
};

export default Projects;
