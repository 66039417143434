import React, { useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

//import logo
import logo from "../../assets/icon/logo1.svg";

const Navbar = () => {
  const nav = useRef();
  const location = useLocation();

  // useEffect(() => {
  //   let isScrolling;
  //   window.addEventListener('scroll', () => {
  //     nav.current.style.boxShadow = '2px 2px 10px #ddd'
  //     window.clearTimeout(isScrolling);
  //     isScrolling = setTimeout(() => {
  //       nav.current.style.boxShadow = 'none'
  //     }, 500);
  //   }, false)
  // }, [])

  const [status, setStatus] = useState(false);

  return (
    <div>
      <header
        style={{ boxShadow: "2px 2px 10px #ddd" }}
        className="py-4 sm:py-5 fixed w-[100%] bg-white !z-50"
      >
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="!flex !items-center !justify-between">
            <div className="shrink-0">
              <Link to="/" title="" className="">
                <img className="w-auto h-auto" src={logo} alt="" />
              </Link>
            </div>

            <div className="hidden lg:flex lg:items-center">
              <Link
                to="/"
                title=""
                className={`inline-flex items-center justify-center mx-3 text-base text-[#2F2F2F] transition-all duration-200 border border-transparent rounded-full focus:text-[#0F74BB] hover:text-[#0F74BB] font-[500] font-[inter] ${
                  location.pathname === "/" && "!text-[#0F74BB]"
                }`}
              >
                Home
              </Link>

              <Link
                to="/aboutUs"
                title=""
                className={`inline-flex items-center justify-center mx-3 text-base text-[#2F2F2F] transition-all duration-200 border border-transparent rounded-full focus:text-[#0F74BB] hover:text-[#0F74BB] font-[500] font-[inter] ${
                  location.pathname === "/aboutUs" && "!text-[#0F74BB]"
                }`}
              >
                About Us
              </Link>

              <Link
                to="/services"
                title=""
                className={`inline-flex items-center justify-center mx-3 text-base text-[#2F2F2F] transition-all duration-200 border border-transparent rounded-full focus:text-[#0F74BB] hover:text-[#0F74BB] font-[500] font-[inter] ${
                  location.pathname === "/services" && "!text-[#0F74BB]"
                }`}
              >
                Services
              </Link>

              <a
                href="https://drive.google.com/file/d/1CbxYrYWw-MEsr5ivom2wVqTWi7jSr9NY/view"
                target={"_blank"}
                title=""
                className={`inline-flex items-center justify-center mx-3 text-base text-[#2F2F2F] transition-all duration-200 border border-transparent rounded-full focus:text-[#0F74BB] hover:text-[#0F74BB] font-[500] font-[inter] ${
                  location.pathname === "/profile" && "!text-[#0F74BB]"
                }`}
                rel="noreferrer"
              >
                Profile
              </a>
              <Link
                to="/gallery"
                title=""
                className={`inline-flex items-center justify-center mx-3 text-base text-[#2F2F2F] transition-all duration-200 border border-transparent rounded-full focus:text-[#0F74BB] hover:text-[#0F74BB] font-[500] font-[inter] ${
                  location.pathname === "/gallery" && "!text-[#0F74BB]"
                }`}
              >
                Gallery
              </Link>
              <Link
                to="/projects"
                title=""
                className={`inline-flex items-center justify-center mx-3 text-base text-[#2F2F2F] transition-all duration-200 border border-transparent rounded-full focus:text-[#0F74BB] hover:text-[#0F74BB] font-[500] font-[inter] ${
                  location.pathname === "/projects" && "!text-[#0F74BB]"
                }`}
              >
                Projects
              </Link>
              <Link
                to="/solar-park"
                title=""
                className={`inline-flex items-center justify-center mx-3 text-base text-[#2F2F2F] transition-all duration-200 border border-transparent rounded-full focus:text-[#0F74BB] hover:text-[#0F74BB] font-[500] font-[inter] ${
                  location.pathname === "/solar-park" && "!text-[#0F74BB]"
                }`}
                rel="noreferrer"
              >
                Solar park
              </Link>
              <Link
                to="/contact"
                title=""
                className={`inline-flex items-center justify-center mx-3 text-base text-[#2F2F2F] transition-all duration-200 border border-transparent rounded-full focus:text-[#0F74BB] hover:text-[#0F74BB] font-[500] font-[inter] ${
                  location.pathname === "/contact" && "!text-[#0F74BB]"
                }`}
              >
                Contact us
              </Link>
            </div>

            {status && (
              <div className="pl-10 pt-20 flex flex-col justify-start lg:hidden fixed top-0 left-0 w-full h-full text-white bg-black lg:items-center">
                <Link
                  to="/"
                  title=""
                  className={`text-white inline-flex mx-3 text-2xl transition-all duration-200 border border-transparent rounded-full focus:text-[#0F74BB] hover:text-[#0F74BB] font-[500] font-[inter] ${
                    location.pathname === "/" && "!text-[#0F74BB]"
                  }`}
                >
                  Home
                </Link>

                <Link
                  to="/aboutUs"
                  title=""
                  className={`inline-flex mt-2 text-white mx-3 text-2xl transition-all duration-200 border border-transparent rounded-full focus:text-[#0F74BB] hover:text-[#0F74BB] font-[500] font-[inter] ${
                    location.pathname === "/aboutUs" && "!text-[#0F74BB]"
                  }`}
                >
                  About Us
                </Link>

                <Link
                  to="/services"
                  title=""
                  className={`inline-flex mt-2 text-white mx-3 text-2xl transition-all duration-200 border border-transparent rounded-full focus:text-[#0F74BB] hover:text-[#0F74BB] font-[500] font-[inter] ${
                    location.pathname === "/services" && "!text-[#0F74BB]"
                  }`}
                >
                  Services
                </Link>

                <Link
                  to="/"
                  title=""
                  className={`inline-flex mt-2 text-white mx-3 text-2xl transition-all duration-200 border border-transparent rounded-full focus:text-[#0F74BB] hover:text-[#0F74BB] font-[500] font-[inter] ${
                    location.pathname === "/profile" && "!text-[#0F74BB]"
                  }`}
                >
                  Profile
                </Link>
                <Link
                  to="/gallery"
                  title=""
                  className={`inline-flex mt-2 text-white mx-3 text-2xl transition-all duration-200 border border-transparent rounded-full focus:text-[#0F74BB] hover:text-[#0F74BB] font-[500] font-[inter] ${
                    location.pathname === "/gallery" && "!text-[#0F74BB]"
                  }`}
                >
                  Gallery
                </Link>
                <Link
                  to="/projects"
                  title=""
                  className={`inline-flex mt-2 text-white mx-3 text-2xl transition-all duration-200 border border-transparent rounded-full focus:text-[#0F74BB] hover:text-[#0F74BB] font-[500] font-[inter] ${
                    location.pathname === "/projects" && "!text-[#0F74BB]"
                  }`}
                >
                  Projects
                </Link>
                <Link
                  to="/solar-park"
                  title=""
                  className={`inline-flex mt-2 text-white mx-3 text-2xl transition-all duration-200 border border-transparent rounded-full focus:text-[#0F74BB] hover:text-[#0F74BB] font-[500] font-[inter] ${
                    location.pathname === "/solar-park" && "!text-[#0F74BB]"
                  }`}
                >
                  Solar park
                </Link>
                <Link
                  to="/contact"
                  title=""
                  className={`inline-flex mt-2 text-white mx-3 text-2xl transition-all duration-200 border border-transparent rounded-full focus:text-[#0F74BB] hover:text-[#0F74BB] font-[500] font-[inter] ${
                    location.pathname === "/contact" && "!text-[#0F74BB]"
                  }`}
                >
                  Contact us
                </Link>
              </div>
            )}

            <div className="hidden sm:flex sm:items-center sm:justify-end sm:space-x-4">
              <Link
                to="/contact"
                title=""
                className="inline-flex items-center justify-center px-6 py-2 text-base font-medium text-[#0F74BB] transition-all duration-200 border border-[#0F74BB] rounded-full bg-white hover:bg-[#0F74BB] hover:text-white"
                role="button"
              >
                Get in touch
              </Link>
            </div>

            <div className="flex ml-4 lg:hidden z-[99999]">
              <button
                type="button"
                onClick={() => setStatus(!status)}
                className={`${
                  status && "!text-[#38996c] !bg-white"
                } inline-flex items-center p-2.5 text-[#38996c] duration-200 border border-[#0F74BB] rounded-full hover:bg-white hover:text-gray-900 transiton-all focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white focus:ring-offset-[#0F74BB]`}
              >
                {status ? (
                  <span x-show="expanded" aria-hidden="true">
                    <svg
                      className="w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                ) : (
                  <span x-show="!expanded" aria-hidden="true">
                    <svg
                      className="w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
