import React, { useEffect, useState } from "react";

//import images
import mapImg from "../../assets/Images/connect/map.svg";
import headerBG from "../../assets/Images/servicesOrGallery/BG.jpg";

//import components
import emailjs from "@emailjs/browser";
import { useRef } from "react";
import { toast } from "react-hot-toast";
import Footer from "../../components/Footer";
import FooterContent from "../../components/FooterContent";
import Navbar from "../../components/Navbar";

const ContactUs = () => {
  const form = useRef();
  const [usersInfo, setUsersInfo] = useState({
    fullName: "",
    email: "",
    message: "",
  });

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (
      usersInfo.fullName === "" ||
      usersInfo.email === "" ||
      usersInfo.message === ""
    ) {
      toast.error("All fields are required");
      return;
    }
    try {
      await emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          form.current,
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
          (result) => {
            toast.success("Email sent successfully");
            setUsersInfo({
              fullName: "",
              email: "",
              message: "",
            });
            e.target.reset();
          },
          (error) => {
            toast.error(error.message);
            setUsersInfo({
              fullName: "",
              email: "",
              message: "",
            });
            e.target.reset();
          }
        );
    } catch (error) {
      toast.error(error.message);
      setUsersInfo({
        fullName: "",
        email: "",
        message: "",
      });
      e.target.reset();
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* navbar */}
      <Navbar />

      <section
        style={{
          backgroundImage: `url(${headerBG})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
        }}
        className="py-[100px] overflow-hidden"
      >
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="mt-20 text-3xl font-semibold tracking-tight text-white sm:text-4xl xl:text-6xl sm:tracking-tight">
              CONNECT WITH US
            </h1>
            <p className="mt-6 text-sm leading-7 text-white lg:leading-5 lg:text-base lg:px-20">
              Our innovative solutions make clean energy possible for households
              businesses, property owners, and more.
            </p>
          </div>
        </div>
      </section>

      {/* connect */}
      <section className="py-12 bg-gray-50 sm:py-16 lg:py-20 xl:py-24">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-x-16 xl:gap-x-36">
            <div className="flex flex-col self-stretch justify-between">
              <div className="flex-1">
                <img src={mapImg} alt="" />
              </div>

              <div className="grid grid-cols-1 gap-8 mt-12 sm:grid-cols-2 lg:mt-auto">
                <div>
                  <h3 className="text-sm flex items-center font-medium tracking-widest text-blue-600 uppercase">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>

                    <span className="text-[#2F2F2F] ml-[10px]">
                      Office Hours
                    </span>
                  </h3>
                  <p className="mt-5 text-base font-medium text-gray-900">
                    Monday-Saturday
                    <br />
                    10:00 am to 7:00 pm
                  </p>
                </div>

                <div>
                  <h3 className="text-sm flex items-center font-medium tracking-widest text-blue-600 uppercase">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                      />
                    </svg>

                    <span className="text-[#2F2F2F] ml-[10px]">
                      Get In Touch
                    </span>
                  </h3>

                  <p className="mt-5 text-base font-medium text-gray-900">
                    044 4206 0952
                    <br />
                    044 2820 1010
                  </p>
                </div>

                <div>
                  <h3 className="text-sm flex items-center font-medium tracking-widest text-blue-600 uppercase">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                      />
                    </svg>

                    <span className="text-[#2F2F2F] ml-[10px]">Email</span>
                  </h3>
                  <p className="mt-5 text-base font-medium text-gray-900">
                    info@adityaenergy.in
                  </p>
                </div>

                <div>
                  <h3 className="text-sm flex items-center font-medium tracking-widest text-blue-600 uppercase">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                      />
                    </svg>

                    <span className="text-[#2F2F2F] ml-[10px]">
                      Our Address
                    </span>
                  </h3>
                  <p className="mt-5 text-base font-medium text-gray-900">
                    No. 112, Nungambakkam High Road,6A, Eldorado Building
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-white shadow-xl rounded-2xl">
              <div className="p-6 sm:p-10">
                <form
                  ref={form}
                  action="#"
                  onSubmit={handleFormSubmit}
                  method="POST"
                  className="space-y-6"
                >
                  <div>
                    <label
                      htmlFor="fullName"
                      className="text-base font-medium text-gray-900"
                    >
                      Your name
                    </label>
                    <div className="mt-2">
                      <input
                        onBlur={(e) =>
                          setUsersInfo({
                            ...usersInfo,
                            fullName: e.target.value,
                          })
                        }
                        type="text"
                        name="fullName"
                        id="fullName"
                        placeholder="Enter your full name"
                        className="block w-full px-4 py-4 text-base text-gray-900 bg-white border border-gray-200 rounded-xl focus:outline-none focus:border-blue-600 focus:ring-1 focus:ring-blue-600"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="email"
                      className="text-base font-medium text-gray-900"
                    >
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        onBlur={(e) =>
                          setUsersInfo({ ...usersInfo, email: e.target.value })
                        }
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter your email"
                        className="block w-full px-4 py-4 text-base text-gray-900 bg-white border border-gray-200 rounded-xl focus:outline-none focus:border-blue-600 focus:ring-1 focus:ring-blue-600"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="message"
                      className="text-base font-medium text-gray-900"
                    >
                      Write your message
                    </label>
                    <div className="mt-2">
                      <textarea
                        onBlur={(e) =>
                          setUsersInfo({
                            ...usersInfo,
                            message: e.target.value,
                          })
                        }
                        name="message"
                        id="message"
                        placeholder="Write us your question here..."
                        rows="4"
                        className="block w-full px-4 py-4 text-base text-gray-900 bg-white border border-gray-200 resize-y rounded-xl focus:outline-none focus:border-blue-600 focus:ring-1 focus:ring-blue-600"
                      ></textarea>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="inline-flex items-center justify-center px-12 py-4 text-base font-medium text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
                  >
                    Send message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* FooterContent */}
      <FooterContent />
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ContactUs;
